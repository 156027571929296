<template>

  <div style="text-align: -webkit-center;">
    <div class="item " style="background-color:#f8f8d8;">
      <div class="flex_1 item_row_left ">业务代表：</div>
      <div class="flex_1 item_row_right base-font-color">{{project.yewudaibiao}}</div>
      <div class="flex_1 item_row_left ">项目金额：</div>
      <div class="flex_1 item_row_right red-font-color">{{formatMoney(project.xiangmujine)}}</div>
      <div class="flex_1 item_row_left ">预计成本：</div>
      <div class="flex_1 item_row_right red-font-color">{{formatMoney(project.yujichengben)}}</div>
      <div class="flex_1 item_row_left ">预计毛利：</div>
      <div class="flex_1 item_row_right red-font-color">{{formatMoney(project.yujimaoli)}}</div>
      <div class="flex_1 item_row_left ">毛利率：</div>
      <div class="flex_1 item_row_right red-font-color">{{project.maolilv}}</div>
      <div class="flex_1 item_row_right base-font-color" style=" margin-left:auto;" @click="checkHiosty_visable=true">流转记录</div>
      <Drawer title="流转记录" width="550" :closable="false" v-model="checkHiosty_visable">
        <Table size="small" width="520" :columns="checkHiosty_columns" :data="checkHiosty_data" :stripe="true" :border="true">

        </Table>
      </Drawer>
    </div>
    <div class="item  justify-center">
      <div>
        <Steps :current="1">
          <Step :title="item.username" v-for="(item,index) in checkHiosty_data " :key="index" :content="item.time"></Step>
        </Steps>
      </div>
    </div>
    <div class="item  justify-center" style="margin-top:30px;">
      <div>
        <Tabs size="small" @on-click="tabClick">
          <TabPane name="jbxx" label="基本信息"></TabPane>
          <TabPane name="kzxx" label="扩展信息"></TabPane>
          <TabPane name="xgfj" label="相关附件"></TabPane>
          <TabPane name="xmcy" label="项目成员"></TabPane>
          <TabPane name="bgjl" label="变更记录"></TabPane>
        </Tabs>
      </div>
    </div>
    <div class="border" :style="{height: scrollerHeight}" ref="jbxx">
      <div class="item">
        <div class="item_row_left flex_1">系统编号：</div>
        <div class="item_row_right flex_2 base-font-color">
          KDA-OA-UI
        </div>
      </div>
      <div class="item">
        <div class="item_row_left flex_1">合同编号：</div>
        <div class="item_row_right flex_2 base-font-color">
          KDA-OA-UI
        </div>
      </div>
      <div class="item">
        <div class="item_row_left flex_1">项目名称：</div>
        <div class="item_row_right flex_7">
          <Input v-model="project.projectname" style="width:98%" />
        </div>
        <div class="item_row_right flex_7">
          <label class="light-font-color">*</label>
        </div>

      </div>
      <div class="item">
        <div class="item_row_left flex_1">客户名称：</div>
        <div class="item_row_right flex_7">
          <AutoComplete v-model="project.username" :data="projectUserList" :filter-method="filterMethod" placeholder="客户名称" style="width:98%">
          </AutoComplete>
        </div>
        <div class="item_row_right flex_7">
          <label class="light-font-color">*请从搜索的结果中选择客户</label>
        </div>
      </div>
      <div class="item">
        <div class="item_row_left flex_1">合同日期：</div>
        <div class="item_row_right flex_1">
          <DatePicker placeholder="合同日期" type="date" v-model="project.date" style="width:95%;" />
        </div>
        <div class="item_row_right flex_7">
          <label class="light-font-color">*请选择合同签署日期,默认当前系统时间</label>
        </div>
      </div>
      <div class="item">
        <div class="item_row_left flex_1">项目阶段：</div>
        <div class="item_row_right flex_2">
          <RadioGroup v-model="project.projectlevel" type="button" button-style="solid">
            <Radio v-for="(item,index) in project.projectlevelList" :key="index" :label="item.label" :disabled="item.disabled"></Radio>
          </RadioGroup>
        </div>

      </div>
      <div class="item">
        <div class="item_row_left flex_1">项目种类：</div>
        <div class="item_row_right flex_6">
          <RadioGroup v-model="project.projecttype" type="button" button-style="solid">
            <Radio v-for="(item,index) in project.projecttypelist" :key="index" :label="item.label" :disabled="item.disabled"></Radio>
          </RadioGroup>
        </div>
      </div>
      <div class="item">
        <div class="item_row_left flex_1">项目类型：</div>
        <div class="item_row_right flex_6">
          <RadioGroup v-model="project.projecttype1" type="button" button-style="solid">
            <Radio v-for="(item,index) in project.projecttype1list" :key="index" :label="item.label" :disabled="item.disabled"></Radio>
          </RadioGroup>
        </div>
      </div>
      <div class="item">
        <div class="item_row_left flex_1">业务归属：</div>
        <div class="item_row_right flex_6">
          <RadioGroup v-model="project.ywgs" type="button" button-style="solid">
            <Radio v-for="(item,index) in project.ywgslist" :key="index" :label="item.label" :disabled="item.disabled"></Radio>
          </RadioGroup>
        </div>
      </div>
      <div class="item">
        <div class="item_row_left flex_1">是否敲定：</div>
        <div class="item_row_right flex_5">
          <RadioGroup v-model="project.sfqd" type="button" button-style="solid">
            <Radio v-for="(item,index) in project.sfqdlist" :key="index" :label="item.label" :disabled="item.disabled"></Radio>
          </RadioGroup>
        </div>
        <div class="item_row_right flex_7">
          <label class="light-font-color">已敲定：已经中标或签订合同/未敲定：仅口头约定，未中标</label>
        </div>
      </div>
      <div class="item">
        <div class="item_row_left flex_1">业绩核算：</div>
        <div class="item_row_right flex_5">
          <RadioGroup v-model="project.yjhs" type="button" button-style="solid">
            <Radio v-for="(item,index) in project.yjhslist" :key="index" :label="item.label" :disabled="item.disabled"></Radio>
          </RadioGroup>
        </div>
        <div class="item_row_right flex_7">
          <label class="light-font-color">参与核算：累计个人年度销售目标/不参与计算：不参与年度销售目标累计</label>
        </div>
      </div>
      <div class="item">
        <div class="item_row_left flex_1">推荐人员：</div>
        <div class="item_row_right flex_7">
          <Input v-model="project.tjry" style="width:98%" />
        </div>
        <div class="item_row_right flex_7">
          <label class="light-font-color"></label>
        </div>
      </div>
      <div class="item">
        <div class="item_row_left flex_1">项目备注：</div>
        <div class="item_row_right flex_7">
          <Input v-model="project.tjry" style="width:98%" />
        </div>
        <div class="item_row_right flex_7">
          <label class="light-font-color"></label>
        </div>
      </div>
    </div>
    <div class="border" :style="{height: scrollerHeight}" ref="kzxx">
      <div class="item margin-top-10">
        <div class="item_row_left flex_1">质保金：</div>
        <div class="item_row_right flex_5">
          <Input v-model="projectkzxx.zbj" style="width:95%" />
        </div>
        <div class="item_row_left flex_1">保证金：</div>
        <div class="item_row_right flex_5">
          <Input v-model="projectkzxx.bzj" style="width:95%" />
        </div>
        <div class="item_row_left flex_1">收款银行：</div>
        <div class="item_row_right flex_5">
          <Input v-model="projectkzxx.skyh" style="width:95%" />
        </div>
        <div class="item_row_left flex_1">收款账号：</div>
        <div class="item_row_right flex_5">
          <Input v-model="projectkzxx.skzh" style="width:95%" />
        </div>
      </div>
      <div class="item margin-top-10">
        <div class="item_row_left flex_1">计划完工日期：</div>
        <div class="item_row_right flex_5">
          <DatePicker placeholder="计划完工日期" type="date" v-model="projectkzxx.jhwgrq" style="width:95%;" />
        </div>
        <div class="item_row_left flex_1">计划验收日期：</div>
        <div class="item_row_right flex_5">
          <DatePicker placeholder="计划验收日期" type="date" v-model="projectkzxx.jhysrq" style="width:95%;" />
        </div>
        <div class="item_row_left flex_1">实际完工日期：</div>
        <div class="item_row_right flex_5">
          <DatePicker placeholder="实际完工日期" type="date" v-model="projectkzxx.sjwgrq" style="width:95%;" />
        </div>
        <div class="item_row_left flex_1">实际验收日期：</div>
        <div class="item_row_right flex_5">
          <DatePicker placeholder="实际验收日期" type="date" v-model="projectkzxx.sjwgrq" style="width:95%;" />
        </div>
      </div>
      <div class="item margin-top-10">
        <div class="item_row_left flex_1">违约条款：</div>
        <div class="flex_6">
          <Input type="textarea" height="80px" />
        </div>
      </div>
    </div>
    <div class="border" :style="{height: scrollerHeight}" ref="xgfj">
      <div class="item margin-top-10">
        <div class="item_row_left flex_1">
          <Upload multiple :show-upload-list="false" action="">
            <!-- action="//jsonplaceholder.typicode.com/posts/" -->
            <Button icon="ios-cloud-upload-outline">上传</Button>
          </Upload>
        </div>
        <div class="item_row_right flex_1">
        </div>
      </div>
      <div class="item margin-top-10" style="width:98%;display: inline-block;text-align: left;">
        <Tag closable @on-close="handleClose(1,2)">
          <div class="item_row_right">
            <label style="width:20px;font-size:20px">
              <Icon type="ios-attach" />
            </label>
            <label class="label-tag">黄石OA.xls</label>
          </div>
          <div class="item_row_right">
            <label style="width:20px">
            </label>
            <label>类型：</label><label>xls</label><label class="margin-left-10">大小：</label><label>40kb</label>
          </div>
        </Tag>
        <Tag closable @on-close="handleClose(1,2)">
          <div class="item_row_right">
            <label style="width:20px;font-size:20px">
              <Icon type="ios-attach" />
            </label>
            <label class="label-tag">黄石OA.xls</label>
          </div>
          <div class="item_row_right">
            <label style="width:20px">
            </label>
            <label>类型：</label><label>xls</label><label class="margin-left-10">大小：</label><label>40kb</label>
          </div>
        </Tag>
        <Tag closable @on-close="handleClose(1,2)">
          <div class="item_row_right">
            <label style="width:20px;font-size:20px">
              <Icon type="ios-attach" />
            </label>
            <label class="label-tag">黄石OA.xls</label>
          </div>
          <div class="item_row_right">
            <label style="width:20px">
            </label>
            <label>类型：</label><label>xls</label><label class="margin-left-10">大小：</label><label>40kb</label>
          </div>
        </Tag>
        <Tag closable @on-close="handleClose(1,2)">
          <div class="item_row_right">
            <label style="width:20px;font-size:20px">
              <Icon type="ios-attach" />
            </label>
            <label class="label-tag">黄石OA.xls</label>
          </div>
          <div class="item_row_right">
            <label style="width:20px">
            </label>
            <label>类型：</label><label>xls</label><label class="margin-left-10">大小：</label><label>40kb</label>
          </div>
        </Tag>
        <Tag closable @on-close="handleClose(1,2)">
          <div class="item_row_right">
            <label style="width:20px;font-size:20px">
              <Icon type="ios-attach" />
            </label>
            <label class="label-tag">黄石OA.xls</label>
          </div>
          <div class="item_row_right">
            <label style="width:20px">
            </label>
            <label>类型：</label><label>xls</label><label class="margin-left-10">大小：</label><label>40kb</label>
          </div>
        </Tag>
        <Tag closable @on-close="handleClose(1,2)">
          <div class="item_row_right">
            <label style="width:20px;font-size:20px">
              <Icon type="ios-attach" />
            </label>
            <label class="label-tag">黄石OA.xls</label>
          </div>
          <div class="item_row_right">
            <label style="width:20px">
            </label>
            <label>类型：</label><label>xls</label><label class="margin-left-10">大小：</label><label>40kb</label>
          </div>
        </Tag>
        <Tag closable @on-close="handleClose(1,2)">
          <div class="item_row_right">
            <label style="width:20px;font-size:20px">
              <Icon type="ios-attach" />
            </label>
            <label class="label-tag">黄石OA.xls</label>
          </div>
          <div class="item_row_right">
            <label style="width:20px">
            </label>
            <label>类型：</label><label>xls</label><label class="margin-left-10">大小：</label><label>40kb</label>
          </div>
        </Tag>
        <Tag closable @on-close="handleClose(1,2)">
          <div class="item_row_right">
            <label style="width:20px;font-size:20px">
              <Icon type="ios-attach" />
            </label>
            <label class="label-tag">黄石OA.xls</label>
          </div>
          <div class="item_row_right">
            <label style="width:20px">
            </label>
            <label>类型：</label><label>xls</label><label class="margin-left-10">大小：</label><label>40kb</label>
          </div>
        </Tag>
        <Tag closable @on-close="handleClose(1,2)">
          <div class="item_row_right">
            <label style="width:20px;font-size:20px">
              <Icon type="ios-attach" />
            </label>
            <label class="label-tag">黄石OA.xls</label>
          </div>
          <div class="item_row_right">
            <label style="width:20px">
            </label>
            <label>类型：</label><label>xls</label><label class="margin-left-10">大小：</label><label>40kb</label>
          </div>
        </Tag>
        <Tag closable @on-close="handleClose(1,2)">
          <div class="item_row_right">
            <label style="width:20px;font-size:20px">
              <Icon type="ios-attach" />
            </label>
            <label class="label-tag">黄石OA.xls</label>
          </div>
          <div class="item_row_right">
            <label style="width:20px">
            </label>
            <label>类型：</label><label>xls</label><label class="margin-left-10">大小：</label><label>40kb</label>
          </div>
        </Tag>
        <Tag closable @on-close="handleClose(1,2)">
          <div class="item_row_right">
            <label style="width:20px;font-size:20px">
              <Icon type="ios-attach" />
            </label>
            <label class="label-tag">黄石OA.xls</label>
          </div>
          <div class="item_row_right">
            <label style="width:20px">
            </label>
            <label>类型：</label><label>xls</label><label class="margin-left-10">大小：</label><label>40kb</label>
          </div>
        </Tag>
      </div>
    </div>
    <div class="border" :style="{height: scrollerHeight}" ref="xmcy">
      <div class="item margin-top-10 " style="width:98%">
        <div class="item_row_right flex_6 " style="height:80%;width:98%">
          <Button icon="ios-add" @click="adduser">添加成员</Button>
        </div>
      </div>
      <div class="item margin-top-10" style="width:98%">
        <Table style="width: 100%" :columns="project_columns" :data="project_data" border>
          <template slot-scope="{ row ,index}" slot="action">
            <AutoComplete v-model="row.name" v-if="row.id==''" transfer :data="projectUserList" :filter-method="filterMethod" @on-change="AutoCompleteselect(row,index)">
            </AutoComplete>
            <span v-if="row.id!=''">{{row.name}}</span>
          </template>
          <template slot-scope="{ row ,index}" slot="button">
            <Button icon="ios-trash" type="error" @click="xmcy_del(row,index)">删除</Button>
            <Button icon="ios-add" type="info" class="margin-left-10" @click="xmcy_add(row,index)">保存</Button>
          </template>
        </Table>

      </div>
    </div>
    <div class="border" :style="{height: scrollerHeight}" ref="bgjl">
      <div class="item margin-top-10" style="width:98%">
        <Table border :show-header="false" style="width: 100%" :columns="change_columns" :data="change_data" @on-expand="change_getinfo">
          <template slot-scope="{ row }" slot="no">
            <span>合同编号：</span> <span class="link-font-color">{{row.no}}</span>
          </template>
          <template slot-scope="{ row }" slot="time">
            <span>变更时间：</span> <span class="link-font-color">{{row.time}}</span>
          </template>
          <template slot-scope="{ row }" slot="content">
            <span>变更内容：</span> <span class="link-font-color">{{row.content}}</span>
          </template>
        </Table>

      </div>
    </div>
    <div >
    </div>
    <Affix :offset-bottom="20" style="text-align: left;margin-left:20px;left: 60px;">
      <Button v-for="(item,index) in buttons" :key="index" :type="item.type" :disabled="item.disabled">{{item.label}}</Button>
    </Affix>
  </div>

</template>
<script>
import { } from "@/api";
import { } from "@/utils/action.js";
import { formatMoney, formatDate } from "@/utils/format.js";

import table from '@/components/web/tablebox'
export default {
  data: function () {
    return {
      attachs: [],
      buttons: [
        {
          label: "冻结合同",
          disabled: false,
        },
        {
          label: "下单",
          disabled: true,
        },

        {
          label: "申请开票",
          disabled: true,
        },
        {
          label: "冻结合同",
          disabled: true,
        },
      ],

      project: {
        yewudaibiao: "戴磊",
        xiangmujine: "2020202.112",
        yujichengben: "2020112.1221",
        yujimaoli: "2020102.2122",
        maolilv: "20%",

        projectname: "坤达安至臻限定",
        username: "客户",
        date: "2021-08-12",

        projectlevelList: [
          { label: "已关闭", disabled: true },
          { label: "商机跟进", disabled: "disabled" },
          { label: "需求确认", disabled: "disabled" },
          { label: "方案汇报", disabled: "disabled" },
          { label: "招投标", disabled: "disabled" },
          { label: "转合同", disabled: false },
          { label: "项目实施", disabled: false },
          { label: "验收", disabled: false },
          { label: "总结", disabled: false },
          { label: "已冻结", disabled: "disabled" },
        ],
        projectlevel: "项目实施",
        projecttypelist: [
          { label: "环保", disabled: false },
          { label: "秸秆", disabled: false },
          { label: "渔政", disabled: false },
          { label: "森林", disabled: false },
          { label: "水域", disabled: false },
          { label: "乡镇", disabled: false },
          { label: "其他", disabled: false },
        ],
        projecttype: "秸秆",
        projecttype1list: [
          { label: "商品销售", disabled: false },
          { label: "硬件集成", disabled: false },
          { label: "软件开发", disabled: false },
          { label: "集成加开发", disabled: false },
          { label: "维保", disabled: false },
          { label: "服务", disabled: false },
          { label: "GD", disabled: false },
        ],
        projecttype1: "集成加开发",

        ywgs: "坤达安",//业务归属
        ywgslist: [
          { label: "同人至臻", disabled: false, },
          { label: "坤达安", disabled: false, },
          { label: "宝利瑞信", disabled: false, },
          { label: "正德恒信", disabled: false, },
          { label: "恺博拓", disabled: false, },
          { label: "坤达安环境", disabled: false, },
          { label: "登峰智造", disabled: false, },
          { label: "齐心", disabled: false, },
        ],
        sfqd: "已敲定", //是否敲定
        sfqdlist: [
          { label: "已敲定", disabled: false },
          { label: "未敲定", disabled: false },
        ],
        yjhs: "参与核算",
        yjhslist: [
          { label: "参与核算", disabled: false },
          { label: "不参与核算", disabled: false },
        ],
        tjry: "",

      },
      projectkzxx: {
        zbj: "30000.00",//质保金
        bzj: "5000.00",
        skyh: "汉口银行",
        skzh: "2989 4037 2974 1231",
        jhwgrq: "2021-04-03",
        jhysrq: "2021-04-03",
        sjwgrq: "2021-04-04",
        sjysrq: "2021-04-04",
        wytk: "",
      },
      projectUserList: [
        "坤达安", "湖北", "黄石", "武汉", "黄冈", "神农架"
      ],
      checkHiosty_visable: false,
      checkHiosty_columns: [
        {
          title: "人员",
          key: "username",
          minWidth: "80px",
          maxWidth: "80px",
        },
        {
          title: "时间",
          key: "time",
          minWidth: "180px",
          maxWidth: "180px",
        },
        {
          title: "类型",
          key: "type",
          minWidth: "80px",
          maxWidth: "80px",
        },
        {
          title: "内容",
          key: "centent",
          minWidth: "180px",
          maxWidth: "180px",
        },
      ],
      checkHiosty_data: [
        { id: "1", username: "戴磊", time: "2012-01-02 12:11:11", type: "提交", centent: "提交", checkstate: "0" },
        { id: "2", username: "贾鹏", time: "2012-01-02 12:11:11", type: "拒绝", centent: "清单有误", checkstate: "-1" },
        { id: "3", username: "戴磊", time: "2012-01-02 12:11:11", type: "提交", centent: "提交", checkstate: "0" },
        { id: "4", username: "贾鹏", time: "2012-01-02 12:11:11", type: "通过", centent: "同意", checkstate: "1" },
        { id: "5", username: "付晴", time: "2012-01-02 12:11:11", type: "通过", centent: "同意，未见asdasdasdas纸质合同", checkstate: "1" },
      ],
      project_columns: [
        {
          title: '姓名',
          key: 'name',
          slot: "action",

        },
        {
          title: '年龄',
          key: 'age',
        },
        {
          title: '地址',
          key: 'address',
        },
        {
          title: '操作',
          slot: "button",
          width: 220
        }
      ],
      project_data: [
        {
          id: "1",
          name: 'John Brown',
          age: 18,
          address: 'New York No. 1 Lake Park',
          date: '2016-10-03'
        },
        {
          id: "2",
          name: 'Jim Green',
          age: 24,
          address: 'London No. 1 Lake Park',
          date: '2016-10-01'
        },
        {
          id: "3",
          name: 'Joe Black',
          age: 30,
          address: 'Sydney No. 1 Lake Park',
          date: '2016-10-02'
        },
        {
          id: "4",
          name: 'Jon Snow',
          age: 26,
          address: 'Ottawa No. 2 Lake Park',
          date: '2016-10-04'
        },

      ],
      change_columns: [
        {
          type: 'expand',
          width: 50,
          render: (h, params) => {
            return h(table, {
              props: {
                row: params.row,
                column: this.children_columns,
                data: this.children_data,
                rowclass: this.rowclass,
              },
            })
          }
        },
        {
          title: "变更单号",
          slot: "no",
          className: " table-aaa-bg "
        },
        {
          title: "变更时间",
          slot: "time",
          className: " table-aaa-bg "
        },
        {
          title: "变更内容",
          slot: "content",
          className: " table-aaa-bg "
        },
      ],
      change_data: [
        { no: "KDA-OA-UI-001", time: "2021-01-01", content: "20102020=>123123123" },
        { no: "KDA-OA-UI-002", time: "2021-01-01", content: "20102020=>123123123" },
        { no: "KDA-OA-UI-003", time: "2021-01-01", content: "20102020=>123123123" },
        { no: "KDA-OA-UI-004", time: "2021-01-01", content: "20102020=>123123123" },
      ],
      change_classrow: "",
      children_columns: [
        {
          title: '状态',
          key: 'state'
        },
        {
          title: '商品名称',
          key: 'name'
        },
        {
          title: '单位',
          key: 'unit'
        },
        {
          title: '数量',
          key: 'sum'
        },
        {
          title: '销售单价',
          key: 'unitmoney'
        },
        {
          title: '销售金额',
          key: 'summoney'
        },
        {
          title: '预计成本单价',
          key: 'unitcb'
        },
        {
          title: '预计成本金额',
          key: 'sumcb'
        },
        {
          title: '备注',
          key: 'content'
        },
      ],
      children_data: [],
    }
  },
  computed: {
    // 滚动区高度
    scrollerHeight: function () {
      return (window.innerHeight - 100) + 'px';
    }
  },
  methods: {
    formatMoney: formatMoney,
    formatDate: formatDate,
    tabClick(name) {
      console.log(name)
      let top = null;
      top = this.$refs[name].offsetTop
      window.scrollTo(0, top - 10);
    },
    filterMethod(value, option) {
      return option.toUpperCase().indexOf(value.toUpperCase()) !== -1;
    },
    adduser() {
      this.project_data.push(
        {
          id: '',
          name: '',
          age: "",
          address: "",
        }
      );
    },
    AutoCompleteselect(row, index) {
      console.log(row);
      console.log(index);
      this.project_data[index] = row;
    },
    xmcy_del(row, index) {
      if (row.id == "") {
        this.project_data.splice(index, 1);
      } else {
        console.log(row);
      }
    },
    xmcy_add(row, index) {
      console.log(row);
      console.log(index);
    },
    change_getinfo(row) {
      console.log(row);
      if (row.no == "KDA-OA-UI-001") {
        this.children_data = [
          {
            state: "-1",
            name: "gtx2080ti",
            unit: "件",
            sum: "1",
            unitmoney: "8000",
            summoney: "80000",
            unitcb: "2000",
            sumcb: "2000",
            content: "备注",
          },
          {
            state: "1",
            name: "gtx2080ti",
            unit: "件",
            sum: "1",
            unitmoney: "9000",
            summoney: "9000",
            unitcb: "2000",
            sumcb: "2000",
            content: "备注",
          },

        ]
      } else this.children_data = []

    },
    rowclass(row) {
      if (row.state == "-1") {
        return "table-error-td";
      } else if (row.state == "1") {
        return "table-success-td";
      }
      return "";

    },
    handleClose(event, name) {
      console.log(event);
      console.log(name);
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", () => {
      });
    });
    this.checkHiosty_data.forEach(element => {
      this.$set(element, "cellClassName", {});
      if (element.checkstate == "0") {
        this.$set(element.cellClassName, "type", "table-info-td")
      } else if (element.checkstate == "1") {
        this.$set(element.cellClassName, "type", "table-success-td")
      } else if (element.checkstate == "-1") {
        this.$set(element.cellClassName, "type", "table-error-td")
      }
    });
    this.buttons = [
      {
        label: "冻结合同",
        disabled: false,
        type: "error",
      },
      {
        label: "下单",
        disabled: false,
        type: "info",
      },

      {
        label: "申请开票",
        disabled: false,
        type: "success",
      },
      
      {
        label: "申请开票",
        disabled: true,
        type: "success",
      },
    ]
  }

}
</script>
<style scoped>
.border {
  margin-bottom: 100px;
  border: 1px solid #ebebeb;
  width: 98%;
}
.ivu-tag {
  height: 60px;
  width: 260px;
  background-color: white;
}

.label-tag {
  width: 200px;
  border-bottom: 1px solid #4f4f4f;
  text-align: left;
}
</style>
<style >
.ivu-tag .ivu-icon-ios-close {
  top: -45px !important;
  left: 230px;
}
</style>



