
<template>
  <div>
    <Table :rowClassName="table_rowClassName" translate="" height="200" :columns="columnsdata" :data='tabledata'>
      <!-- <template slot="action" slot-scope="{ row }"> -->
      <!-- <Button
                            size="small"
                            type="text"
                            @click="handDetails(row)"
                    >
                        删除</Button>
                    <Button
                            size="small"
                            type="text"
                            @click="ShowaddOrder()"
                    >
                        添加</Button> -->
      <!--<Button-->
      <!--size="small"-->
      <!--type="text"-->
      <!--@click="editorModal('bj')"-->
      <!--&gt;-->
      <!--编辑</Button>-->
      <!-- <Dropdown trigger="click" :transfer='transfer' style="margin-left: 10px">
                        <a href="javascript:void(0)">
                            更多
                            <Icon type="ios-arrow-down"></Icon>
                        </a>
                        <DropdownMenu slot="list">
                            <DropdownItem  @click.native="Showdiscount(row)">
                                折让
                            </DropdownItem>
                            <DropdownItem @click.native="handDetails('SH')">
                                经理审核
                            </DropdownItem>
                            <DropdownItem @click.native="OutTicketMange()">
                                开票
                            </DropdownItem>
                            <DropdownItem @click.native="OrderConfirm(row)">
                                开单确认
                            </DropdownItem>
                            <DropdownItem @click.native="ChildModal(row)">
                                子订单列表
                            </DropdownItem>
                        </DropdownMenu>
                    </Dropdown> -->
      <!-- </template>  -->
    </Table>
  </div>
</template>
<script>
export default {
  props: {
    row: Object,
    column: null,
    data: null,
    rowclass: Function,
  },
  data() {
    return {
      tabledata: [],
      columnsdata: [
      ],
      rowdata: {},
    }
  }, 
  methods: {
    table_rowClassName(row, index) {
      return this.rowclass(row, index);
    },
  },
  created() {
    this.columnsdata = this.column;
    this.tabledata = this.data;
    this.rowdata = this.row;

  },
};

</script>
<style scoped>
.expand-row {
  margin-bottom: 16px;
}
</style>